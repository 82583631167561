<template>
  <div>
    <v-form ref="validForm" lazy-validation>
    <v-container cols="12" class="pt-0 mt-0">
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-card outlined>
            <v-card-title>
              <h3>
                <v-icon class="mr-3" color="primary">
                  mdi-format-list-checks
                </v-icon>
                Novo tipo de solicitação
              </h3>
            </v-card-title>

            <v-card-text>
              <v-container>
                  <v-row class="pa-0">
                    <v-col md="10" lg="8" xs="12" class="pa-0">
                      <v-text-field dense outlined required label="Nome" @change="changeDisplayName" v-model="display_name"
                        :rules="defaultRules"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0">
                    <v-col md="10" lg="8" xs="12" class="pa-0">
                      <v-textarea dense outlined required auto-grow rows="3" label="Descrição"
                        v-model="description"></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col lg="4" md="5" cols="12" class="pl-0 py-0">
                      <label>SLA de Primeira Interação</label>
                      <v-row>
                        <v-col md="4" cols="4" class="py-0">
                          <v-text-field dense outlined required type="number" label="Dias"
                            v-model="sla_validate_days"></v-text-field>
                        </v-col>
                        <v-col md="4" cols="4" class="py-0">
                          <v-text-field dense outlined required type="number" label="Horas"
                            v-model="sla_validate_hours"></v-text-field>
                        </v-col>
                        <v-col md="4" cols="4" class="py-0">
                          <v-text-field dense outlined required type="number" label="Minutos"
                            v-model="sla_validate_minutes"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col lg="4" md="5" cols="12" class="pa-0">
                      <label>SLA de Finalização</label>
                      <v-row>
                        <v-col md="4" cols="4" class="py-0">
                          <v-text-field dense outlined required type="number" label="Dias"
                            v-model="sla_finish_days"></v-text-field>
                        </v-col>
                        <v-col md="4" cols="4" class="py-0">
                          <v-text-field dense outlined required type="number" label="Horas"
                            v-model="sla_finish_hours"></v-text-field>
                        </v-col>
                        <v-col md="4" cols="4" class="py-0">
                          <v-text-field dense outlined required type="number" label="Minutos"
                            v-model="sla_finish_minutes"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col md="10" lg="8" xs="12" class="pa-0">
                      <v-autocomplete dense outlined required clearable label="Fornecedor" v-model="provider_id"
                        :items="providers" item-text="name" item-value="id" :rules="defaultRules" :loading="!isLoaded" @change="changeProvider">
                        <template v-slot:selection="data">
                          {{ data.item.name }} - {{ data.item.email }}
                        </template>
                        <template v-slot:item="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item"></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.name"></v-list-item-title>
                              <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                <v-row>
                  <v-col md="10" lg="8" xs="12" class="pa-0">
                    <SolicitationTypeTagsComponent
                      v-if="isLoaded"
                      :required="true"
                      @save="saveTags"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="10" lg="8" xs="12" class="pa-0">
                    <v-autocomplete dense outlined required clearable label="Automação" v-model="automation_id"
                      :items="automations" item-text="display_name" item-value="id" :loading="!isLoaded">
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.display_name"></v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            v-html="data.item.description"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="10" lg="8" xs="12" class="pa-0">
                    <SolicitationTypeFieldsComponent v-if="isLoaded" />
                  </v-col>
                </v-row>
                <v-row v-if="isLoaded">
                  <v-col md="10" lg="8" xs="12" class="pa-0">
                    <v-sheet
                      class="mx-auto mt-6 pa-0"
                      elevation="6"
                    >
                      <v-col cols="12" >
                        <EmailApproverComponent
                          :index="0"
                          :selectedId="null"
                          @changeSubject="changeSubject($event)"
                          @changeMessage="changeMessage($event)"
                          @changeTo="changeTo($event)"
                          @changeCc="changeCc($event)"
                          @disableButton="disableButton($event)"
                          :defaultEmail="defaultToEmail"
                          :solicitationType="true"
                          :toEmail="to_email"
                          :ccEmail="cc_email"
                          :messageEmail="''"
                          :subjectEmail="subject_email"
                        />
                      </v-col>
                    </v-sheet>
                  </v-col>
                </v-row>
                <v-col md="10" lg="8" xs="12" class="pa-0 py-5">
                  <v-row justify="end">
                    <v-btn outlined color="primary" :to="{ name: 'solicitation-type-list' }" exact class="ma-2">Voltar
                    </v-btn>
                    <v-btn class="ma-2" color="primary" :loading="btn_loading" :disabled="btnDisable"
                      @click="saveSolicitationTypeItem">Salvar
                    </v-btn>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    </v-form>
  </div>
</template>

<script>
import SolicitationTypeTagsComponent from "@/components/solicitationType/SolicitationTypeTagsComponent";
import SolicitationTypeFieldsComponent from "@/components/solicitationType/SolicitationTypeFieldsComponent";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import EmailApproverComponent from "../../components/solicitationType/EmailApproverComponent.vue";

export default {
  components: {
    EmailApproverComponent,
    SolicitationTypeTagsComponent,
    SolicitationTypeFieldsComponent
  },
  data() {
    return {
      btn_loading: false,
      creating: false,
      btnDisable: false,
      valid: true,
      isLoaded: false,
      defaultRules: [v => !!v || "Campo obrigatório"],
      sla_first_rule: [],
      display_name: "",
      description: "",
      sla_validate_days: "",
      sla_validate_hours: "",
      sla_validate_minutes: "",
      sla_finish_days: "",
      sla_finish_hours: "",
      sla_finish_minutes: "",
      defaultToEmail: "",
      provider_id: "",
      providers: [],
      automations: [],
      automation_id: "",
      subject_email: "",
      message_email: "",
      to_email: "",
      cc_email: [],
      tags: []
    };
  },
  async mounted() {
    this.changeSolicitationTypeNewOrEdit("");
    await this.loadSolicitationTypeNew();
    await Promise.all([
      this.loadProvider(),
      this.loadAutomation(),
    ]).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.providers = this.provider_list.data;
    this.automations = this.automation_list.data;
    this.isLoaded = true;
  },

  computed: {
    ...mapState("provider", ["provider_list"]),
    ...mapState("automation", ["automation_list"]),
    ...mapState("solicitationType", [
      "fields",
      "fields_required",
      "solicitationType_save",
      "solicitationType_update"
    ]),
    ...mapGetters("solicitationType", ["getFields", "getFieldsRequired"])
  },
  methods: {
    ...mapActions("provider", ["loadProvider"]),
    ...mapActions("solicitationType", [
      "changeSolicitationTypeNewOrEdit",
      "saveSolicitationType",
      "updateSolicitationType",
      "loadSolicitationTypes",
      "loadSolicitationTypeNew"
    ]),
    ...mapActions("automation", ["loadAutomation"]),

    saveTags (value) {
      this.tags = value;
    },

    async saveSolicitationTypeItem() {
      if (this.$refs.validForm.validate()) {
        this.btn_loading = true;

        let body = {
          display_name: this.display_name,
          description: this.description,
          sla_validate_days: this.sla_validate_days,
          sla_validate_hours: this.sla_validate_hours,
          sla_validate_minutes: this.sla_validate_minutes,
          sla_finish_days: this.sla_finish_days,
          sla_finish_hours: this.sla_finish_hours,
          sla_finish_minutes: this.sla_finish_minutes,
          provider_id: this.provider_id,
          automation_id: this.automation_id,
          fields_required: this.fields_required,
          fields: this.fields,
          subject_email: this.subject_email,
          message_email: this.message_email,
          to_email: this.to_email,
          cc_email: this.cc_email,
          tags: this.tags
        };
        await this.saveSolicitationType(body).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });

        if (!this.solicitationType_save.success) {
          this.$swal("Oops ...", this.solicitationType_save.message, "warning");
          this.btn_loading = false;
        } else {
          this.btn_loading = true;
          this.$swal({
            icon: "success",
            title: this.solicitationType_save.message,
            showCancelButton: true,
            confirmButtonText: "Continuar cadastrando",
            cancelButtonText: "Listagem",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#445E93"
          }).then(result => {
            if (!result.value) {
              this.$router.push({
                name: "solicitation-type-list"
              });
            } else {
              this.$router.go();
            }
          });
        }
      }
    },

    changeDisplayName() {
      this.subject_email = this.display_name + ' - {{company}} - {{cnpj_empresa}}';
    },

    changeSubject(item) {
      this.subject_email = item.title;
    },

    changeMessage(item) {
      this.message_email = item.message;
    },

    changeTo(item) {
      this.to_email = item.to;
    },

    changeCc(item) {
      this.cc_email = item.cc;
    },

    disableButton(item) {
      this.btnDisable = item.value;
    },

    changeProvider(providerId) {
      const selectedProvider = this.providers.find(provider => provider.id === providerId);
      if (selectedProvider) {
        this.to_email = selectedProvider.email;
        this.defaultToEmail = selectedProvider.email;
      }
    }
  }
};
</script>
